export enum CSVProductHeaders {
  ID = 'ID',
  SKU = 'SKU',
  PARENT_ID = 'Parent ID',
  PARENT_SKU = 'Parent SKU',
  FULL_NAME = 'Full Name',
  NAME = 'Name',
  PARENT_NAME = 'Parent Name',
  IMAGE_URL = 'Image URL',
  IS_ACTIVE = 'Is Active',
  IS_MASTER_PRODUCT = 'Is Master Product',
  IS_TRACKING_INVENTORY = 'Is Tracking Inventory',
  PRICE = 'Retail Price',
  CATEGORY = 'Category',
  BRAND = 'Brand',
  DEPOSIT_FEE = 'Deposit Fee',
  TAX = 'Tax',
  PURCHASE_PRICE = 'Wholesale Cost',
  DESCRIPTION = 'Description',
  BARCODE = 'Barcode',
  SHORT_DESCRIPTION = 'Receipt Description',
  WEIGHT = 'Net Product Weight',
  CANNABIS_WEIGHT = 'Cannabis Weight',
  CANNABIS_VOLUME = 'Cannabis Volume',
  THC = 'THC',
  CBD = 'CBD',
  MAX_CBD = 'Max CBD',
  MIN_CBD = 'Min CBD',
  MAX_THC = 'Max THC',
  MIN_THC = 'Min THC',
  SHOW_MAX_MIN = 'Show Min Max THC CBD',
  UNIT = 'THC/CBD Unit',
  TRACK_LOTS = 'Track Lots',
  CUSTOM_HEADER_PREFIX = 'Custom-',
  TAGS = 'Tags',
  STRAIN_TYPE = 'Strain Type',
  CREATED = 'Created',
  UPDATED = 'Updated',
  SELL_TYPE = 'Sell Type',
  AVAILABLE_ONLINE = 'Available Online',
  IS_DELETED = 'Is Deleted',
  LOCATION_OVERRIDE_HEADER_PREFIX = 'Override-',
  SUPPLIER_ID = 'Supplier ID',
  VENDOR_IDS = 'Vendor IDs',
  IMAGE_URL_0 = 'Image URL 0',
  IMAGE_URL_1 = 'Image URL 1',
  IMAGE_URL_2 = 'Image URL 2',
  IMAGE_URL_3 = 'Image URL 3',
  IMAGE_URL_4 = 'Image URL 4',
}

export enum LocationOverrideCSVHeaders {
  LOCATION_ID = 'Location ID',
  IS_ACTIVE = 'Is Active',
  BARCODE = 'Barcode',
  RETAIL_PRICE = 'Retail Price',
  WHOLESALE_COST = 'Wholesale Cost',
}
