import { ProductModels } from '@getgreenline/products'
import { LocationOverrideCSVHeaders } from '../constants/CSVHeaders'
import {
  ICSVProductImport,
  INestedCSVProduct,
} from '../containers/Admin/CompanySection/AdminProductImport'
import {
  INestedProductNetworkObject,
  IProductNetworkObject,
} from '../containers/Dashboard/DashboardProducts/AddProduct/ProductStore'
import { GrPrice } from './helpers'
import _ from 'lodash'

export const getValue = (row: any, index: number | null | undefined) => {
  if (!row || index === null || index === undefined) {
    return null
  }
  const rawValue = row[index]
  if (!rawValue || rawValue === '') {
    return null
  } else {
    return rawValue
  }
}

export const parseBooleanOrUndefined = (value: string | null | undefined): boolean | undefined => {
  if (value == null) return undefined
  const normalizedValue = value.toUpperCase().trim()
  if (normalizedValue === 'TRUE') return true
  if (normalizedValue === 'FALSE') return false
  return undefined
}

export const convertStringToArray = (value: string) => {
  if (!value) return
  return value.split(',').map((v: string) => v.trim())
}

export const validateParentChildIsDeletedAgreement = (
  product: INestedCSVProduct | INestedProductNetworkObject,
) => {
  if (!product.childProducts.length) return

  const parentIsDeleted = product.isDeleted
  const allChildrenAreDeleted = (
    product.childProducts as Array<ICSVProductImport | IProductNetworkObject>
  ).every((child) => child.isDeleted)

  if (parentIsDeleted && !allChildrenAreDeleted) {
    throw new Error(
      'Parent product must not be deleted if any variant is not deleted. Product skipped.',
    )
  }

  if (!parentIsDeleted && allChildrenAreDeleted) {
    throw new Error('Parent product must be deleted if all variants are deleted. Product skipped.')
  }

  return
}

const validateParentChildSupplierAgreement = (
  product: INestedCSVProduct | INestedProductNetworkObject,
) => {
  if (!('supplierId' in product)) return

  const parentSupplierId = product.supplierId
  const allChildrenHaveSameSupplier = (product.childProducts as Array<IProductNetworkObject>).every(
    (child) => child.supplierId === parentSupplierId,
  )

  if (!allChildrenHaveSameSupplier) {
    throw new Error(
      'All variants must have the same supplier as the parent product. Product skipped.',
    )
  }
}

const validateParentChildVendorAgreement = (
  product: INestedCSVProduct | INestedProductNetworkObject,
) => {
  const parentVendorIds = product.vendorIds
  const allChildrenHaveSameVendors = (
    product.childProducts as Array<ICSVProductImport | IProductNetworkObject>
  ).every((child) => _.isEqual(child.vendorIds, parentVendorIds))

  if (!allChildrenHaveSameVendors) {
    throw new Error(
      'All variants must have the same vendors as the parent product. Product skipped.',
    )
  }
}

const validateVendorLimit = (product: INestedCSVProduct | INestedProductNetworkObject) => {
  if (product.vendorIds && product.vendorIds.length > 5) {
    throw new Error('A parent product cannot have more than 5 vendors. Product skipped.')
  }

  for (const child of product.childProducts ?? []) {
    if (child.vendorIds && child.vendorIds.length > 5) {
      throw new Error('A variant product cannot have more than 5 vendors. Product skipped.')
    }
  }
}

export const validateProduct = (product: INestedCSVProduct | INestedProductNetworkObject) => {
  validateVendorLimit(product)

  if (!product.childProducts.length) return

  validateParentChildIsDeletedAgreement(product)
  validateParentChildSupplierAgreement(product)
  validateParentChildVendorAgreement(product)
}

const locationOverrideFieldMapping: Record<string, keyof ProductModels.ILocationOverride> = {
  [LocationOverrideCSVHeaders.LOCATION_ID]: 'locationId',
  [LocationOverrideCSVHeaders.IS_ACTIVE]: 'isActive',
  [LocationOverrideCSVHeaders.BARCODE]: 'barcode',
  [LocationOverrideCSVHeaders.RETAIL_PRICE]: 'retailPrice',
  [LocationOverrideCSVHeaders.WHOLESALE_COST]: 'wholesaleCost',
}

export const sanitizeLocationOverrideValue = (
  field: string,
  value: string,
): number | string | boolean | undefined => {
  switch (field) {
    case LocationOverrideCSVHeaders.LOCATION_ID:
      return isNaN(Number(value)) ? undefined : Number(value)

    case LocationOverrideCSVHeaders.RETAIL_PRICE:
    case LocationOverrideCSVHeaders.WHOLESALE_COST:
      const pennyValue = GrPrice.convertDollarToCent(value)
      return isNaN(pennyValue) ? undefined : pennyValue

    case LocationOverrideCSVHeaders.IS_ACTIVE:
      const csvIsActive = value.toUpperCase()
      return csvIsActive === 'TRUE' ? true : csvIsActive === 'FALSE' ? false : undefined

    case LocationOverrideCSVHeaders.BARCODE:
      return value || undefined

    default:
      return undefined
  }
}

export const mapLocationOverrides = (
  locationOverrideHeaders: string[],
  row: string[] | Record<string, string>,
  indicesByHeaders?: Map<string, number>,
) => {
  const locationOverridesMap = new Map<number, ProductModels.ILocationOverride>()

  locationOverrideHeaders.forEach((overrideHeader) => {
    const segments = overrideHeader.split('-')
    if (segments.length < 3) return

    const overrideIndex = Number(segments[1])
    if (isNaN(overrideIndex)) return

    const overrideField = segments[2].trim()
    const mappedField = locationOverrideFieldMapping[overrideField]
    if (!mappedField) return

    let fieldValue: string
    if (Array.isArray(row)) {
      if (!indicesByHeaders) return

      const columnIndex = indicesByHeaders.get(overrideHeader)
      if (columnIndex === undefined) return

      fieldValue = getValue(row, columnIndex)?.trim()
    } else {
      fieldValue = row[overrideHeader]?.trim()
    }
    if (!fieldValue) return

    let locationOverride = locationOverridesMap.get(overrideIndex)
    if (!locationOverride) {
      locationOverride = { locationId: NaN }
      locationOverridesMap.set(overrideIndex, locationOverride)
    }

    const sanitizedValue = sanitizeLocationOverrideValue(overrideField, fieldValue)
    if (sanitizedValue !== undefined) {
      ;(locationOverride as any)[mappedField] = sanitizedValue
    }
  })

  return Array.from(locationOverridesMap.values()).filter((override) => override.locationId)
}
