import * as Papa from 'papaparse'
import { isArray } from 'util'
import {
  IPayment,
  TransactionStatus,
  Province,
  ITransaction,
  GiftCardPaymentTypeIds,
  CreatePayment,
  CreatePaymentLine,
  PaymentTypeIds,
  CreateTransaction,
  CreateGiftCardTransaction,
  IPaymentQueueWithExternalSource,
  ICompany,
  API,
} from '@getgreenline/homi-shared'
import { groupBy } from 'lodash'
import { ISignedImageUploadContract, CurrentCompanyStore } from '../stores/CurrentCompanyStore'
import { OptionProps } from 'antd/lib/select'
import { SelectProps } from 'antd-v4/lib/select'
import { CloudinaryResponse } from '../components/ImageUploadModal'
import { CLOUDINARY, BLAZE_COMPANY } from '../constants/general'
import isEmail from 'validator/lib/isEmail'
import { OrderedExportDataFields } from '../constants/OrderedExportDataFields'
import { ProductModels } from '@getgreenline/products'
import { ILocationContract } from '@getgreenline/locations/build/models'
import { InventoryLogModels } from '@getgreenline/inventory/'
import QRCode from 'qrcode'
import { polygon } from '@turf/helpers'
import bbox from '@turf/bbox'
import centerOfMass from '@turf/center-of-mass'
import buffer from '@turf/buffer'
import { LngLatBoundsLike } from 'maplibre-gl'
import { CategoriesModels } from '@getgreenline/categories'

interface IEmailDetail {
  mailto: string
  cc?: string
  subject?: string
  body?: string
}

export interface UploadFile {
  lastModified: number
  lastModifiedDate: Date
  name: string
  preview: string
  size: number
  type: string
  webkitRelativePath: string
}

export interface DropzoneFile {
  lastModified: number
  lastModifiedDate: Date
  name: string
  originFileObj: UploadFile
  percent: string
  size: number
  type: string
  status: string
  uid: string
}

export class GrPrice {
  private static convertNumberPriceToStringPrice(rawPrice: number) {
    const absolutePrice = Math.abs(rawPrice)
    const twoDecimalStringPrice = GrPrice.convertDecimalString(absolutePrice)
    // Add $ sign
    const dollarString = `$${twoDecimalStringPrice}`

    return rawPrice < 0 ? `-${dollarString}` : dollarString
  }

  static convertCentToDollar(centValue: number) {
    return `$${(centValue / 100).toFixed(2)}`
  }

  static convertDollarToCent(dollarValue: string) {
    const num = parseFloat(dollarValue.replace('$', ''))
    return Math.round(num * 100)
  }

  static convertDollarToDollar(dollarValue: number) {
    return GrPrice.convertNumberPriceToStringPrice(dollarValue)
  }

  static convertDollarToShortFormDollar = (rawPrice: number) => {
    switch (true) {
      case rawPrice > 1000000000:
        return GrPrice.convertDollarToDollar(rawPrice / 1000000000) + ' B'
      case rawPrice > 1000000:
        return GrPrice.convertDollarToDollar(rawPrice / 1000000) + ' M'
      case rawPrice > 1000:
        return GrPrice.convertDollarToDollar(rawPrice / 1000) + ' K'
      default:
        return GrPrice.convertDollarToDollar(rawPrice)
    }
  }

  static renderDollarString(rawPrice: number | null) {
    const dollarValue = rawPrice ? rawPrice / 100 : 0

    return GrPrice.convertNumberPriceToStringPrice(dollarValue)
  }

  static convertDecimalString(
    amount: number,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  ) {
    return amount.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits })
  }

  static profitMarginPercentage(cost: number, revenue: number) {
    const profit = revenue - cost
    const margin = (profit / revenue) * 100
    return margin && isFinite(margin) ? `${margin.toFixed(2)}%` : '-%'
  }
}

export class GrRegex {
  // Auto escapes special characters
  static regEscape = (str: string) => {
    // eslint-disable-next-line no-useless-escape
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  static replaceLatin1Space = (str: string) => {
    return str.replace(/[\xa0]/g, ' ')
  }

  static isValidCRSA = (str: string) => {
    const crsaRegex = /^CRSA[0-9]{7}$/
    return crsaRegex.test(str)
  }

  static replaceDoubleQuotes = (str: string) => {
    return str.replace(/\\?"/g, "'")
  }

  static isValidUrl = (url: string) => {
    const urlPattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/[^\s]*)?$/
    return urlPattern.test(url)
  }

  // eslint-disable-next-line no-control-regex
  static unicodeRegex = new RegExp(/[^\x00-\x7F]+/g)

  static notNumberRegex = new RegExp(/[^\d]+\b/)

  static cleanNotNumbers = (str: string) => {
    return str.replace(/\D/g, '')
  }

  // Replace some frequently appearing special characters into acceptable counter parts
  static cleanFrequentSpecialCharacters = (str: string, isJsonOutput?: boolean) => {
    let cleanString = str
    if (isJsonOutput) {
      cleanString = cleanString.replace(/“/g, `\\"`).replace(/”/g, `\\"`)
    } else {
      cleanString = cleanString.replace(/“/g, `"`).replace(/”/g, `"`)
    }
    return cleanString
      .replace(/¼/g, '1/4')
      .replace(/’/g, `'`)
      .replace(/‘/g, `'`)
      .replace(/`/g, `'`)
      .replace(/•/g, '-')
      .replace(/–/g, '-')
      .replace(/—/g, '-')
      .replace(/[\u202F\u00A0]/g, ' ')
      .replace(GrRegex.unicodeRegex, '')
  }

  static dateRegex = new RegExp(/[^0-9/]/g)

  static postalCodeCA = new RegExp(/^([A-Z]([0-9]([A-Z]([0-9]([A-Z]([0-9])?)?)?)?)?)$/)
  static specialCharacters = new RegExp(/[+?^$[\]{}()\\./<>|;]/g)
  static excludeFlatRate = new RegExp(/^(?!.*\bflat\s*rate\b).*$/i)
}

export class GrTime {
  private static createTimeStr = ({
    duration,
    singluarUnit,
  }: {
    duration: number
    singluarUnit: string
  }): string => {
    let str: string

    if (duration === 0) {
      str = ``
    } else if (0 < duration && duration <= 1) {
      str = `${duration} ${singluarUnit}`
    } else {
      str = `${duration} ${singluarUnit}s`
    }

    return str
  }

  static convertMinsToHrsMins = (minutes: number): string => {
    if (minutes === 0) {
      return '0 hr'
    }

    const h = Math.floor(minutes / 60)
    const m = minutes % 60

    const hour = GrTime.createTimeStr({ duration: h, singluarUnit: 'hr' })
    const min = GrTime.createTimeStr({ duration: m, singluarUnit: 'min' })

    return min ? `${hour} ${min}` : hour
  }
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const filterOption = (input: string, option: React.ReactElement<OptionProps>) => {
  const optionName = option.props.children

  if (optionName && typeof optionName === 'string') {
    const lowerCaseInput = input.toLowerCase().trim()

    return optionName.trim().toLowerCase().indexOf(lowerCaseInput) >= 0
  }

  return true
}

export const filterOptionV4: SelectProps<object>['filterOption'] = (inputValue, optionData) => {
  if (!optionData) return true

  // `optionData.children` only works when use children as option data
  const optionName = optionData.label ? optionData.label : optionData.children

  if (optionName && typeof optionName === 'string') {
    const lowerCaseInput = inputValue.toLowerCase().trim()

    return optionName.trim().toLowerCase().indexOf(lowerCaseInput) >= 0
  }

  return true
}

export function parseAndDownloadCSV(filename: string, json: any, fields?: string[]) {
  let csvString: string
  if (fields) {
    csvString = Papa.unparse({
      fields: fields,
      data: json,
    })
  } else {
    csvString = Papa.unparse(json)
  }
  // Reference: https://github.com/mholt/PapaParse/issues/175
  const blob = new Blob([csvString])
  if (navigator?.msSaveBlob) {
    navigator.msSaveBlob(blob, `${filename}.csv`)
  } else {
    const a = window.document.createElement('a')
    a.href = (window.URL as any).createObjectURL(blob, { type: 'text/plain' } as any)
    a.download = `${filename}.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export function downloadCSV(filename: string, csvData: any) {
  const blob = new Blob([csvData])
  if (navigator?.msSaveBlob) {
    navigator.msSaveBlob(blob, filename)
  } else {
    const a = window.document.createElement('a')
    a.href = (window.URL as any).createObjectURL(blob, { type: 'text/plain' } as any)
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

interface IPrintHTMLOptions {
  qrCodeCanvasId?: string
  qrCodeUrl?: string
}

export function printHTML(id: string, title?: string, options?: IPrintHTMLOptions) {
  // Add iFrame (where printing HTML is set)
  const iframeId = `print-frame-${new Date().getTime()}`
  const iframe = document.createElement('iframe')
  iframe.id = iframeId
  iframe.name = iframeId
  iframe.src = 'about:blank'
  document.body.appendChild(iframe)

  // Configure printFrame
  const printCSS = (window.document.getElementById('printing-css') as HTMLTextAreaElement).value
  const printContent = window.document.getElementById(id)!.innerHTML

  const printFrame = (window as any).frames[iframeId]
  printFrame.document.title = title
  printFrame.document.body.innerHTML = '<style>' + printCSS + '</style>' + printContent

  // Render QRCodein Canvas
  if (options?.qrCodeCanvasId && options?.qrCodeUrl) {
    const blankCanvas = printFrame.document.getElementById(options.qrCodeCanvasId)
    if (blankCanvas) QRCode.toCanvas(blankCanvas, options.qrCodeUrl)
  }

  // Print printFrame
  printFrame.window.focus()
  printFrame.window.print()

  // Remove iframe
  document.getElementById(iframeId)!.outerHTML = ''
}

export const parseErrorMsg = (error: any): string | undefined => {
  if (error?.response?.data?.errors) {
    return error.response.data.errors.map(({ detail }: { detail: string }) => detail).join('\n')
  }

  return error?.response?.data?.message || error?.message
}

export const jsonToQueryString = (json: any): string => {
  return Object.keys(json)
    .filter((key) => json[key] !== undefined)
    .map((key) => {
      if (isArray(json[key])) {
        return encodeURIComponent(key) + '=' + JSON.stringify(json[key])
      } else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
      }
    })
    .join('&')
}

export const transactionsIncomplete = (payment: IPayment) => {
  const pendingTransactions = payment.transactions.filter(
    (trans) =>
      trans.status === TransactionStatus.PENDING || trans.status === TransactionStatus.PROCESSING,
  )
  return pendingTransactions.length > 0
}

export function makeRandomAlphaNumericString(): string {
  return Math.random().toString(36).slice(2)
}

export function makeDefaultTooltipId(label: string): string {
  const kebabCase = label.toLowerCase().replace(/ /g, (str) => '-')
  return `${kebabCase}-${makeRandomAlphaNumericString()}`
}

/**
 * Add leading zeros to javascript numbers
 *
 * @param {*} num
 * @param {*} padlen
 * @param {*} padchar
 * @returns
 */
export function numberPadder(num: number, padlen: number, padchar?: string): string {
  const pad_char = typeof padchar !== 'undefined' ? padchar : '0'
  const pad = new Array(1 + padlen).join(pad_char)
  return (pad + num).slice(-pad.length)
}

export function getCategoryName(category: CategoriesModels.IBaseCategory) {
  if (category.parentCategoryId) {
    return `> ${category.name}`
  }
  return category.name
}

export const swapArrayElements = (array: any[], index1: number, index2: number) => {
  const updatedArray = [...array]
  const tempElement = updatedArray[index1]
  updatedArray[index1] = updatedArray[index2]
  updatedArray[index2] = tempElement
  return updatedArray
}

export const getUniqueArray = (
  array: Array<string | number | null>,
): Array<string | number | null> => [...new Set(array)]

export const getArrayDuplicates = (
  array: Array<string | number | null>,
): Array<string | number | null> => {
  const duplicates = array.filter((value, index, self) => self.indexOf(value) !== index)
  return [...new Set(duplicates)]
}

export const getUniqueArrayOfObjects = (arrayOfObjects: any[], uniqueKey: string): any[] => {
  const uniqueResults = []
  const map = new Map()

  for (const object of arrayOfObjects) {
    if (!map.has(object[uniqueKey])) {
      map.set(object[uniqueKey], true)
      uniqueResults.push({ ...object })
    }
  }

  return uniqueResults
}

export const uploadFileToS3Bucket = (
  file: any,
  signedUrl: string,
  contentType: string,
): XMLHttpRequest => {
  const xhr = new XMLHttpRequest()

  xhr.open('PUT', signedUrl)
  xhr.setRequestHeader('Content-Type', contentType)
  xhr.setRequestHeader('x-amz-acl', 'public-read')
  xhr.send(file)

  return xhr
}

export const uploadImageToCloudinary = (
  file: any,
  uploadUrlDetails: ISignedImageUploadContract,
): { xhr: XMLHttpRequest; formData: FormData } => {
  const { apiKey, timestamp, url, signature } = uploadUrlDetails

  const xhr = new XMLHttpRequest()

  xhr.open('POST', url)

  const formData = new FormData()
  formData.set('file', file)
  formData.set('signature', signature)
  formData.set('timestamp', timestamp)
  formData.set('api_key', apiKey)

  return { xhr, formData }
}

export const uploadImageAndGetCloudinaryUrl = async (
  file: any,
  currentCompanyStore: CurrentCompanyStore,
): Promise<string> => {
  if (!file.includes(CLOUDINARY)) {
    const uploadUrlDetails: ISignedImageUploadContract = currentCompanyStore?.company?.id
      ? await currentCompanyStore!.getSignedImageUploadPublicUrl()
      : await currentCompanyStore!.getSignedAdminImageUploadPublicUrl()

    const { xhr, formData } = await uploadImageToCloudinary(file, uploadUrlDetails)
    return new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status === 200) {
          const responseData: CloudinaryResponse = JSON.parse(xhr.responseText)
          resolve(responseData.secure_url)
        } else {
          reject(new Error(xhr.statusText))
        }
      }
      xhr.send(formData)
    })
  } else {
    return file
  }
}

export const uploadPaymentOptionImage = async (
  fileList: any,
  uploadUrlDetails: ISignedImageUploadContract | null,
): Promise<string> => {
  if (!uploadUrlDetails) throw new Error('Upload url details not found')
  const { xhr, formData } = uploadImageToCloudinary(fileList[0], uploadUrlDetails)

  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status === 200) {
        const responseData: CloudinaryResponse = JSON.parse(xhr.responseText)
        resolve(responseData.secure_url)
      } else {
        console.log('error xhr', xhr)
        reject(new Error(xhr.statusText))
      }
    }
    xhr.send(formData)
  })
}

const createPromiseForCloudinaryUpload = async (
  index: number,
  url: string,
  uploadUrlDetails: ISignedImageUploadContract,
): Promise<[number, string]> => {
  if (url.includes(CLOUDINARY)) {
    return [index, url]
  }

  const { xhr, formData } = uploadImageToCloudinary(url, uploadUrlDetails)

  return new Promise<string>((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status !== 200) {
        reject(
          new Error(
            `An error occured while uploading Image URLs. Please verify that Image URL "${url}" is valid.`,
          ),
        )
      }

      const responseData: CloudinaryResponse = JSON.parse(xhr.responseText)
      resolve(responseData.secure_url)
    }

    xhr.send(formData)
  }).then((cloudinaryUrl) => [index, cloudinaryUrl])
}

export const uploadImagesAndGetCloudinaryUrlsSimultaneously = async (
  urlMap: Map<number, string>,
  currentCompanyStore: CurrentCompanyStore,
): Promise<Map<number, string>> => {
  const uploadUrlDetails: ISignedImageUploadContract = currentCompanyStore?.company?.id
    ? await currentCompanyStore!.getSignedImageUploadPublicUrl()
    : await currentCompanyStore!.getSignedAdminImageUploadPublicUrl()

  const cloudinaryUrlsMap = new Map<number, string>()

  const uploadPromises = Array.from(urlMap.entries()).map(([index, url]) =>
    createPromiseForCloudinaryUpload(index, url, uploadUrlDetails),
  )

  const uploadResults = await Promise.all(uploadPromises)

  uploadResults.forEach(([index, cloudinaryUrl]) => {
    cloudinaryUrlsMap.set(index, cloudinaryUrl)
  })

  return cloudinaryUrlsMap
}

export const prepopulateEmail = (emailDetail: IEmailDetail): string => {
  let result = ''
  const keys = Object.keys(emailDetail)

  keys.forEach((key, index) => {
    const separator = key === 'mailto' ? ':' : '='

    const emailString = `${key}${separator}${encodeURIComponent(emailDetail[key])}`

    let joiner = key === 'mailto' ? '?' : '&'
    if (index === keys.length - 1) {
      joiner = ''
    }

    result += `${emailString}${joiner}`
  })

  return result
}

export const parseProvinceProductAgency = (province: Province | null) => {
  switch (province) {
    case 'AB':
      return 'AGLC'
    case 'ON':
      return 'OCS'
    case 'BC':
      return 'BCLDB'
    default:
      return `${BLAZE_COMPANY} catalogue`
  }
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const orderedExportData = (fields: string[], data?: any, taxHeaders?: string[]) => {
  if (!data) {
    return []
  }
  const ordered = data.map((row: any) => {
    const taxes = {}
    if (taxHeaders) {
      const groupedTaxes = groupBy(row[OrderedExportDataFields.TAXES], 'Tax')
      taxHeaders.forEach((header: string) => {
        if (groupedTaxes[header]) {
          taxes[header] = parseFloat(
            groupedTaxes[header][0][OrderedExportDataFields.AMOUNT].toFixed(2),
          )
        } else {
          taxes[header] = 0
        }
      })
    }

    let orderedObject = {}
    fields.forEach((field) => {
      if (field !== OrderedExportDataFields.TAXES) {
        if (typeof row[field] === 'number') {
          orderedObject[field] = parseFloat((row[field] as number).toFixed(2))
        } else if (field === OrderedExportDataFields.DATE) {
          orderedObject[field] = row[field].substring(0, 10)
        } else {
          orderedObject[field] = row[field]
        }
      } else {
        orderedObject = { ...orderedObject, ...taxes }
      }
    })

    return orderedObject
  })

  return ordered
}

export const abbreviateText = (text: string, maxNum = 20) => {
  if (text.length < maxNum) return { isAbbr: false, text }

  const abbrText = text.substring(0, maxNum) + '...'

  return { isAbbr: true, text: abbrText }
}

export const splitHighlightWords = (word: string) => {
  return [...word.split(' ')]
}

export const isGiftCardTransaction = (transaction: ITransaction) =>
  GiftCardPaymentTypeIds.includes(transaction.paymentTypeId)

export const isWorldpayTransaction = (
  transaction: CreateTransaction | CreateGiftCardTransaction | ITransaction,
) => transaction.paymentTypeId === PaymentTypeIds.worldpay

export const removeDecimalPoint = (value: number | string) => {
  return Number(`${value}`.replace('.', ''))
}

export const getPopupContainer = () => {
  // https://ant.design/components/select/#Select-props
  return (document.getElementById('app-main-view') as HTMLElement) || document.body
}

export function csvStringToFile(csvString: string, filename: string) {
  const blob = new Blob([csvString])

  const a = window.document.createElement('a')
  a.href = (window.URL as any).createObjectURL(blob, { type: 'text/plain' } as any)
  a.download = `${filename}.csv`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const checkTypeWithProperty = <objectType extends {}, propKey extends PropertyKey>(
  obj: objectType,
  prop: propKey,
): obj is objectType & Record<propKey, unknown> => {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

export const isPhoneNumberValid = (phone: string) => {
  const phoneRegex = /^\+?[0-9]{10,11}$/

  // Validate if there is phone number
  return phone ? phoneRegex.test(phone) : true
}

export const isASCII = (str: string, extended = false) => {
  return (extended ? /^[\x20-\xFF]*$/ : /^[\x20-\x7F]*$/).test(str)
}

export const isEmailValid = (email: string) => {
  // Use Validate.js to validate email address. Returns true if no email address passed
  return email ? isEmail(email) && isASCII(email) : true
}

export const getFullNotes = (isNextLine: boolean, notes?: string, paymentQueueNotes?: string) => {
  const space = isNextLine ? `\n` : ` `
  if (notes) {
    if (notes !== paymentQueueNotes) {
      // To handle old notes which will have same notes in paymentQueue and payment
      if (paymentQueueNotes) {
        return notes + space + paymentQueueNotes // To combine sales notes and paymentQueue notes
      } else {
        return notes
      }
    } else {
      return notes
    }
  } else {
    if (paymentQueueNotes) {
      return paymentQueueNotes
    } else {
      return ''
    }
  }
}

export const replaceArrayValue = <T extends unknown>(array: T[], search: T, replace: T) => {
  const newArr = [...array]
  const index = newArr.indexOf(search)
  if (index !== -1) {
    newArr[index] = replace
  }

  return newArr
}

export const bulkRenameObjectProperty = <T extends unknown>(
  array: T[] | undefined,
  oldProperty: keyof T,
  newProperty: keyof T,
) => {
  return array?.map((object) => {
    if (object[oldProperty]) {
      object[newProperty] = object[oldProperty]
      delete object[oldProperty]
    }
    return object
  })
}

export const sanitizeHtmlTag = (element: string) => {
  const htmlTagSanitized = element.replace(/(<([^>]+)>)/gi, '')
  return htmlTagSanitized
}

export const isSystemProduct = (product?: { productType: ProductModels.ProductType }) => {
  return (
    product?.productType === ProductModels.ProductType.FEE ||
    product?.productType === ProductModels.ProductType.TIP
  )
}

export const checkForQuantity = (nonBundleProductLines: CreatePayment['nonBundleProductLines']) => {
  const initial = 0
  return (
    nonBundleProductLines.reduce(
      (accumulator: number, currentValue: CreatePaymentLine) => accumulator + currentValue.units,
      initial,
    ) === 0
  )
}

export function hasCashAmountErr(payment: CreatePayment) {
  const cashPayCheck = payment.transactions.some(
    (trans) =>
      Math.abs(trans.amount) < 5 &&
      Math.abs(trans.amount) > 0 &&
      trans.paymentTypeId === PaymentTypeIds.cash,
  )

  return (
    (payment.tendered > Math.abs(payment.totalAmount) && payment.refundPaymentId) || !!cashPayCheck
  )
}

interface IAccumulator {
  addition: number
  reduction: number
}

export const getTotalUnitDiscrepancies = (flattenedLogs: InventoryLogModels.IInventoryLog[]) => {
  const { addition, reduction } = flattenedLogs.reduce(
    (prev: IAccumulator, log: InventoryLogModels.IInventoryLog) => {
      if (log.updateValue >= 0) {
        prev.addition = prev.addition + log.updateValue
      } else {
        prev.reduction = prev.reduction + log.updateValue
      }
      return prev
    },
    { addition: 0, reduction: 0 },
  )
  const totalAddition = addition
  const totalReduction = reduction
  const totalLog = totalAddition - Math.abs(totalReduction)
  return {
    sumOfAdditions: totalAddition,
    sumOfReductions: totalReduction,
    total: totalLog,
  }
}

export const sortLogsByTotalDiscrepancy = (
  current: InventoryLogModels.IInventoryLog[],
  next: InventoryLogModels.IInventoryLog[],
) => {
  const { total: totalCurrent } = getTotalUnitDiscrepancies(current)
  const { total: totalNext } = getTotalUnitDiscrepancies(next)
  return Math.abs(totalNext) - Math.abs(totalCurrent)
}

export const getLocationsWithCRSA = (locations: ILocationContract[] | undefined) => {
  const filterValidCRSALocation = (location: ILocationContract) =>
    GrRegex.isValidCRSA(location.storeId || '')

  const locationsWithCRSA: ILocationContract[] = locations
    ? locations.filter(filterValidCRSALocation)
    : []

  return locationsWithCRSA
}

export const getCustomerPhoneFromParkedSale = async ({
  parkedSaleModalQueue,
  company,
}: {
  parkedSaleModalQueue?: IPaymentQueueWithExternalSource
  company?: ICompany
}) => {
  if (!company || !parkedSaleModalQueue || !parkedSaleModalQueue.customerId) {
    return
  }

  const customerResponse = await API.getCustomerById(company.id, parkedSaleModalQueue.customerId)

  return customerResponse.customer.phone || undefined
}

export const isDev = (companyId: number) => {
  const devCompnaies = new Set([13])

  if (process.env.REACT_APP_ROOT_API_URL === 'https://api.getgreenline.co') {
    return false
  }

  return devCompnaies.has(companyId)
}

export const parseProductBulkUpdateErrorMsg = (error: any): string | undefined =>
  error?.response?.data?.customErrorObject?.details ||
  error?.response?.data?.details ||
  error?.response?.data?.errors

export const parseProductBulkUpdateErrorTitle = (error: any): string | undefined =>
  error?.response?.data?.titleMessage || error?.response?.data?.message

export const abgrToRgba = (abgr: string): string | undefined => {
  if (abgr.length !== 8) return

  const alpha = abgr.slice(0, 2)
  const blue = abgr.slice(2, 4)
  const green = abgr.slice(4, 6)
  const red = abgr.slice(6, 8)
  return `${red}${green}${blue}${alpha}`
}

export const rgbaToHex = (rgba: string) => `#${rgba}`

export function GeoJSONHelpers(coordinates: number[][]) {
  const pointsLike = coordinates as [number, number][]
  const geoJSON = polygon([pointsLike])

  return {
    getMaxBounds: () => {
      const buffered = buffer(geoJSON, 1, { steps: 1, units: 'kilometers' })

      if (!buffered) {
        throw new Error('Failed to buffer geoJSON')
      }

      return bbox(buffered) as LngLatBoundsLike
    },
    getCenter: () => {
      return centerOfMass(geoJSON).geometry.coordinates
    },
    toGeoJSON: () => {
      return {
        type: 'FeatureCollection',
        features: [geoJSON],
      }
    },
  }
}

export function OutreachHelpers() {
  const cannabisWords = [
    'cannabis',
    'marijuana',
    'weed',
    'cbd',
    'thc',
    'pot',
    'kush',
    'hemp',
    'joint',
  ]
  const productWords = [
    'vape',
    'edible',
    'preroll',
    'pre-roll',
    'tincture',
    'concentrates',
    'gummies',
    'gummy',
  ]
  const descriptiveWords = ['strain', 'hybrid', 'indica', 'sativa']
  const others = ['bud', 'wax', 'shatter', 'dab', 'oil', 'capsule', 'spliff', 'bong']
  const regex = new RegExp(
    `(${[...cannabisWords, ...productWords, ...descriptiveWords, ...others].join('|')})`,
    'i',
  )

  return {
    hasInvalidWord: (str: string) => {
      return regex.test(str)
    },
    highlightInvalidWords: (str: string) => {
      return str
        .split(/\s+/)
        .map((word) =>
          word.replace(
            regex,
            `<strong class="text-danger" style="background-color: yellow">$1</strong>`,
          ),
        )
        .join(' ')
    },
  }
}

export const isValidEnumValue = <T extends Record<string, unknown>>(
  value: unknown,
  expectedValues: T,
): boolean => {
  if (!value) return false
  const enumValues = Object.values(expectedValues) as string[]
  return enumValues.includes(`${value}`)
}
